<template>
  <div class="bgcard">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('lang.taocanlsit')" name="first">
        <div class="headerMsg">
          <div class="addbtn">
            <button @click="toadddetails">{{ $t("lang.Addpackage") }}</button>
            <div class="basic">
              <div>{{ $t("lang.istaocan") }}：</div>
              <div>
                <el-switch
                  v-model="istypes"
                  active-color="#13ce66"
                  inactive-color="#dbdbdb"
                  @change="changetypes"
                >
                </el-switch>
              </div>
            </div>
          </div>
          <div class="searchlist">
            <div class="query">
              <div class="inputname">
                <input
                  v-model="parmes.keyword"
                  :placeholder="$t('lang.Pleaseinputkeywords')"
                />
              </div>
              <div class="searchbtn">
                <button @click="sherachs">
                  <p>{{ $t("lang.search") }}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="serverfroms">
          <el-table :data="tableData.content" style="width: 100%">
            <template slot="empty">
              <div class="noCart">{{ $t("lang.tableNull") }}</div>
            </template>
            <el-table-column
              prop="name"
              :label="$t('lang.Packagename')"
              align="center"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="unit"
              :label="$t('lang.Months')"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="startTime"
              :label="$t('lang.starttime')"
              align="center"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="endTime"
              :label="$t('lang.deadline')"
              align="center"
              show-overflow-tooltip
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="orderBy"
              :label="$t('lang.sort')"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="chargingUnit"
              :label="$t('lang.Costunit')"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="unitPrice"
              :label="$t('lang.Costunitprice')"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="discountPrice"
              :label="$t('lang.Discountfee')"
              align="center"
              width="130"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="flowQuota"
              :label="$t('lang.Flowlimit')"
              align="center"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="storageQuota"
              :label="$t('lang.storagequota')"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              :label="$t('lang.Excesstrafficstrategy')"
              align="center"
              width="250"
            >
              <template #default="scope">
                <span v-if="scope.row.flowStrategy == 1">{{
                  $t("lang.Additionalbilling")
                }}</span>
                <span v-if="scope.row.flowStrategy == 0">{{
                  $t("lang.OutofService")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="flowAdditionalUnit"
              :label="$t('lang.danjiafujia')"
              align="center"
              width="270"
            >
              <template #default="scope">
                <span v-if="scope.row.flowAdditionalUnit">{{
                  scope.row.flowAdditionalUnit
                }}</span>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('lang.cucunxiane')"
              align="center"
              width="260"
            >
              <template #default="scope">
                <span v-if="scope.row.storageStrategy == 1">{{
                  $t("lang.Additionalbilling")
                }}</span>
                <span v-if="scope.row.storageStrategy == 0">{{
                  $t("lang.OutofService")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="storageAdditionalUnit"
              :label="$t('lang.fujiacucen')"
              align="center"
              width="270"
            >
              <template #default="scope">
                <span v-if="scope.row.storageAdditionalUnit">{{
                  scope.row.storageAdditionalUnit
                }}</span>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="tips"
              :label="$t('lang.Purchasetips')"
              align="center"
              width="250"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="notice"
              :label="$t('lang.Serviceinstructions')"
              align="center"
              width="250"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
              :label="$t('lang.operation')"
              width="250"
              align="center"
            >
              <template #default="scope">
                <div class="bottom_tabes">
                  <el-tooltip
                    effect="dark"
                    :content="$t('lang.edit')"
                    placement="left"
                    v-if="scope.row.sysStat == 1"
                  >
                    <div
                      class="bottom_imgs bottom_compile"
                      @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                      @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                      @click="closemsg(scope.row, 1)"
                    >
                      <img
                        class="imgOne"
                        v-if="scope.row.flag1 === 0"
                        src="@/assets/imgs/bottom/bianji.png"
                        alt=""
                      />
                      <img
                        class="imgTwo"
                        v-if="scope.row.flag1 === 1"
                        src="@/assets/imgs/bottom/bianji2.png"
                        alt=""
                      />
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    :content="$t('lang.edit')"
                    placement="left"
                    v-else
                  >
                    <div
                      class="bottom_imgs bottom_compile"
                      @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                      @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                      @click="closemsg(scope.row, 2)"
                    >
                      <img
                        class="imgOne"
                        v-if="scope.row.flag2 === 0"
                        src="@/assets/imgs/bottom/bianji.png"
                        alt=""
                      />
                      <img
                        class="imgTwo"
                        v-if="scope.row.flag2 === 1"
                        src="@/assets/imgs/bottom/bianji2.png"
                        alt=""
                      />
                    </div>
                  </el-tooltip>
                  <div class="btn_dise">|</div>
                  <el-tooltip
                    effect="dark"
                    :content="$t('lang.details')"
                    placement="left"
                  >
                    <div
                      class="bottom_imgs bottom_detail"
                      @mouseenter="enterOne(3, scope.row.id, scope.row.flag3)"
                      @mouseleave="leaveOne(3, scope.row.id, scope.row.flag3)"
                      @click="detailsmsg(scope.row)"
                    >
                      <img
                        class="imgOne"
                        v-if="scope.row.flag3 === 0"
                        src="@/assets/imgs/bottom/xiangqing.png"
                        alt=""
                      />
                      <img
                        class="imgTwo"
                        v-if="scope.row.flag3 === 1"
                        src="@/assets/imgs/bottom/xiangqing2.png"
                        alt=""
                      />
                    </div>
                  </el-tooltip>
                  <div class="btn_dise">|</div>
                  <el-tooltip
                    effect="dark"
                    :content="$t('lang.deletes')"
                    placement="left"
                  >
                    <div
                      class="bottom_imgs bottom_delete"
                      @mouseenter="enterOne(4, scope.row.id, scope.row.flag4)"
                      @mouseleave="leaveOne(4, scope.row.id, scope.row.flag4)"
                      @click="deletemsg(scope.row)"
                    >
                      <img
                        class="imgOne"
                        v-if="scope.row.flag4 === 0"
                        src="@/assets/imgs/bottom/shanchu.png"
                        alt=""
                      />
                      <img
                        class="imgTwo"
                        v-if="scope.row.flag4 === 1"
                        src="@/assets/imgs/bottom/shanchu2.png"
                        alt=""
                      />
                    </div>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div
            style="display: flex; align-items: center"
            v-if="tableData.content"
          >
            <div class="allNum">
              {{ $t("lang.total") }}: {{ tableData.totalElements }}
            </div>
            <el-pagination
              popper-class="paging"
              background
              layout="prev, pager, next"
              :current-page="Number(parmes.page)"
              :total="tableData.totalElements"
              @current-change="handlePageCurrentChange"
              :prev-text="$t('lang.previouspage')"
              :next-text="$t('lang.nextpage')"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane  :label="$t('lang.xiaofeilieo')" name="second">
        <div class="searchlist">
          <div class="searchtime" style="margin-right: 0.4rem">
            <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
            <el-date-picker
              v-model="value1"
              type="daterange"
              :range-separator="$t('lang.to')"
              :start-placeholder="$t('lang.Startdate')"
              :end-placeholder="$t('lang.Enddate')"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              @change="times"
            >
            </el-date-picker>
          </div>
          <div class="query">
            <div class="inputname">
              <input
                v-model="parsm.keyword"
                :placeholder="$t('lang.addnames')"
              />
            </div>
            <div class="searchbtn">
              <button @click="search">{{ $t("lang.query") }}</button>
            </div>
          </div>
        </div>
        <div class="tabelss" style="margin-top:20px">
          <el-table :data="tableDataad.content" style="width: 100%">
            <template slot="empty">
              <div class="noCart">{{ $t("lang.tableNull") }}</div>
            </template>
            <el-table-column
              prop="mobile"
              :label="$t('lang.bankNumber')"
              align="center"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('lang.Packagename')"
              align="center"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="unit"
              :label="$t('lang.Months')"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="startTime"
              :label="$t('lang.Timeofpurchase')"
              align="center"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="chargingUnit"
              :label="$t('lang.Costunit')"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="unitPrice"
              :label="$t('lang.Costunitprice')"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="discountPrice"
              :label="$t('lang.Discountfee')"
              align="center"
              width="130"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="flowQuota"
              :label="$t('lang.Flowlimit')"
              align="center"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="storageQuota"
              :label="$t('lang.storagequota')"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              :label="$t('lang.Excesstrafficstrategy')"
              align="center"
              width="250"
            >
              <template #default="scope">
                <span v-if="scope.row.flowStrategy == 1">{{
                  $t("lang.Additionalbilling")
                }}</span>
                <span v-if="scope.row.flowStrategy == 0">{{
                  $t("lang.OutofService")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="flowAdditionalUnit"
              :label="$t('lang.danjiafujia')"
              align="center"
              width="270"
            >
              <template #default="scope">
                <span v-if="scope.row.flowAdditionalUnit">{{
                  scope.row.flowAdditionalUnit
                }}</span>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('lang.cucunxiane')"
              align="center"
              width="260"
            >
              <template #default="scope">
                <span v-if="scope.row.storageStrategy == 1">{{
                  $t("lang.Additionalbilling")
                }}</span>
                <span v-if="scope.row.storageStrategy == 0">{{
                  $t("lang.OutofService")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="storageAdditionalUnit"
              :label="$t('lang.fujiacucen')"
              align="center"
              width="270"
            >
              <template #default="scope">
                <span v-if="scope.row.storageAdditionalUnit">{{
                  scope.row.storageAdditionalUnit
                }}</span>
                <span v-else>/</span>
              </template>
            </el-table-column>
          </el-table>
          <div
            style="text-align: right"
            class="paginatss"
            v-if="tableDataad.content && tableDataad.content.length > 0"
          >
            <div class="total">
              <span>{{ $t("lang.Total") }}</span>
              {{ tableDataad.totalElements }}
              <span>{{ $t("lang.Strip") }}</span>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="tableDataad.totalElements"
              @current-change="handleChange"
              :prev-text="$t('lang.previouspage')"
              :next-text="$t('lang.nextpage')"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 删除模态框 -->
    <el-dialog
      :title="$t('lang.Deletestaocan')"
      :visible.sync="showSees"
      :show-close="false"
      width="25%"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <div class="delete">
        <span>{{ $t("lang.removeserver") }}</span>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSees = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="determine">
            <p>{{ $t("lang.determine") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as contract from "@/api/contract.js";
export default {
  data() {
    return {
      activeName: "first",
      names: "",
      istypes:true,
      writeval: true,
      parmes: {
        keyword: "",
        page: 1,
        size: 10,
      },
      id: "",
      parsm: {
        page: 1,
        size: 10,
        keyword: "",
        stime: "",
        etime: "",
        buyStime: "",
        buyEtime: "",
      },
      value1: "",
      tableData: [],
      tableDataad: "",
      showSees: false,
    };
  },
  mounted() {
    if (this.$route.query.page) {
      this.parmes.page = this.$route.query.page;
    }

    this.packgeLIsts();
    this.list();
    this.listads()
  },
  methods: {
    listads(){
      contract.mediagetOpenMeal({type:""}).then((res) => {
           this.istypes = res.data == 1?true:false;
          });
    },
    changetypes(e){
      let isad = e?1: 0;
      contract.mediagetOpenMeal({type:isad}).then((res) => {
            if ((res.data = "SUCCESS")) {
              this.$message.success(this.$t("lang.addsuccess"));
            }
          });
    },
    sherachs() {
      this.parmes.page = 1;
      this.packgeLIsts();
    },
    //打开删除模态框
    deletemsg(row) {
      this.showSees = true;
      this.id = row.id;
    },
    //删除套餐
    determine() {
      contract.mealRecordDelete(this.id).then((res) => {
        this.$message.success(this.$t("lang.Operations"));
        this.isLastPage();
        this.packgeLIsts();
      });
      this.showSees = false;
    },
    closemsg(row, index) {
      this.$router.push({
        path: "packagelistdetail",
        query: { data: JSON.stringify(row), page: this.parmes.page },
      });
    },
    enterOne(index, ids, flag) {
      for (let i in this.tableData.content) {
        if (index === 1) {
          if (ids == this.tableData.content[i].id && flag === 0) {
            this.tableData.content[i].flag1 = 1;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.content[i].id && flag === 0) {
            this.tableData.content[i].flag2 = 1;
          }
        }
        if (index === 3) {
          if (ids == this.tableData.content[i].id && flag === 0) {
            this.tableData.content[i].flag3 = 1;
          }
        }
        if (index === 4) {
          if (ids == this.tableData.content[i].id && flag === 0) {
            this.tableData.content[i].flag4 = 1;
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
      for (let i in this.tableData.content) {
        if (index === 1) {
          if (ids == this.tableData.content[i].id && flag === 1) {
            this.tableData.content[i].flag1 = 0;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.content[i].id && flag === 1) {
            this.tableData.content[i].flag2 = 0;
          }
        }
        if (index === 3) {
          if (ids == this.tableData.content[i].id && flag === 1) {
            this.tableData.content[i].flag3 = 0;
          }
        }
        if (index === 4) {
          if (ids == this.tableData.content[i].id && flag === 1) {
            this.tableData.content[i].flag4 = 0;
          }
        }
      }
    },
    detailsmsg(row, ind) {
      // this.alone = row;
      // this.sign = ind;
      // this.title = this.$t("lang.Contractdetails");
      // this.showSee = true;
      this.$router.push({
        path: "packagelistdetail",
        query: {
          data: JSON.stringify(row),
          page: this.parmes.page,
          deside: true,
        },
      });
    },
    async packgeLIsts() {
      let res = await contract.mealRecordlist(this.parmes);
      console.log(res);
      if (res.data) {
        res.data.content.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
          item.flag3 = 0;
          item.flag4 = 0;
        });
        this.tableData = res.data;
      }
    },
    //判断当前页是否是最后一页
    isLastPage() {
      let lastPage = Math.ceil(
        (this.tableData.totalElements - 1) / this.parmes.size
      );
      //console.log(lastPage)
      if (this.parmes.page == lastPage || this.parmes.page > lastPage) {
        this.parmes.page = lastPage;
      }
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parmes.page = page;
      this.packgeLIsts();
    },
    // 新增或编辑
    toadddetails() {
      this.$router.push({
        path: "packagelistdetail",
      });
    },

    //获取订单列表
    async list() {
      let res = await contract.userMeallist(this.parsm);
      console.log(res);
      this.tableDataad = res.data;
    },
    // //订单状态筛选
    // orderstat(e) {
    //   this.parsm.page = 1;
    //   this.parsm.size = 10;
    //   this.list();
    // },
    //时间筛选
    times(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.buyStime = e[0];
        this.parsm.buyEtime = e[1];
      } else {
        this.parsm.buyStime = "";
        this.parsm.buyEtime = "";
      }
      this.list();
    },
    //订单编号筛选
    search() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.list();
    },
    //分页页数改变
    handleChange(page) {
      this.parsm.page = page;
      this.list();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__item {
  color: #c2c2c2;
}
/deep/.el-tabs__item.is-active {
  color: #438749;
}
/deep/.el-tabs__item:hover {
  color: #438749;
}
/deep/.el-tabs__active-bar {
  background: #438749;
}
/deep/.el-date-table .current div span {
  background: #307b37 !important;
}
.date-style {
  background: #307b37 !important;
}
.basic {
 
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0.2rem;
  div {
    color: #dbdbdb;
    font-size: 0.16rem;
  }

  span {
    margin-left: 0.14rem;
    width: 80%;
    border-top: 0.01rem solid #797979;
  }
}
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
  display: flex;
  justify-content: space-between;
  .addbtn {
    display: flex;
    button {
      height: 0.5rem;
      border: none;
      outline-style: none;
      padding: 0px 0.12rem;
      background: #307b37;
      border-radius: 0.1rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.lsitads {
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete {
  height: 0.5rem;
  text-align: center;
  font-size: 0.16rem;
  color: #333333;
}
.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 1px solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}

.contents {
  padding: 0.35rem 0.45rem;
  box-sizing: border-box;
  .header {
    font-size: 0.16rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #307b37;
    span:first-child {
      display: inline-block;
      width: 0.04rem;
      height: 0.2rem;
      background-color: #307b37;
      border-radius: 2px;
      margin-right: 0.07rem;
    }
  }
  .searchlist {
    margin-top: 0.28rem;
    font-size: 0.16rem;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    color: #c2c2c2;
    align-items: center;
    margin-bottom: 0.28rem;
  }
}
/deep/.tabelss {
  .el-table {
    border: 1px solid #9a9a9a;
    .has-gutter tr th {
      background-color: #222628;
      color: #c2c2c2;
      border-color: #9a9a9a;
    }
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-color: #9a9a9a;
  }
  .el-table::before {
    display: none;
  }
  .el-table tbody tr {
    background: #171b1e;
    width: 100%;
    font-size: 0.14rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #c2c2c2;
    td {
      border-color: #9a9a9a;
    }
  }
  .el-table tbody tr:last-child {
    td {
      border-bottom: none;
    }
  }
  .el-table tbody tr:hover > td {
    background-color: #171b1e !important;
  }
  .el-table__empty-block {
    background-color: #171b1e;
  }
}
.buttonsa {
  outline-style: none;
  border: 1px solid #307b37;
  border-radius: 4px;
  background-color: #171b1e;
  font-family: PingFang SC;
  color: #307b37;
  position: relative;
  cursor: pointer;
}
.buttons {
  outline-style: none;
  border: none;
  border-radius: 4px;
  font-family: PingFang SC;
  color: #ffffff;
  position: relative;
  background-color: #307b37;
  cursor: pointer;
}
/deep/.paginatss {
  margin-top: 0.3rem;
  display: flex;
  .total {
    width: auto;
    height: 0.42rem !important;
    line-height: 0.42rem;
    padding: 0px 0.15rem;
    margin-top: 2px;
    box-sizing: border-box;
    background: #171b1e;
    border: 1px solid #464748;
    border-radius: 2px;
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
  }
  .el-pagination span {
    width: auto;
    padding: 0.06rem 0.05rem;
    line-height: 0.28rem;
  }
}
/deep/ .query .inputname input {
  background-color: #171b1e;
}
/deep/ .searchlist .searchtime .el-input__inner {
  background-color: #171b1e;
}
/deep/ .searchlist .searchtime .el-input__inner .el-range-input {
  background-color: #171b1e;
}
.shopll {
  padding: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #b2b2b2;
  .goodsList {
    width: 19%;
    margin-bottom: 0.25rem;
    .goodsimg {
      width: 100%;
      height: 2rem;
    }
    .bordergoods {
      width: 100%;
      background: #1f2224;
      padding: 0.04rem 0.08rem;
      box-sizing: border-box;
      // border-left: 1px solid #b2b2b2;
      // border-right: 1px solid #b2b2b2;
      // border-bottom: 1px solid #b2b2b2;
      .goodsname {
        width: 100%;
        font-size: 0.2rem;
        padding: 0.1rem 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .prices {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .priceleft {
          font-size: 0.18rem;
          color: red;
        }
        .btnsprice {
          display: inline-block;
          padding: 0.08rem 0.18rem;
          border-radius: 2px;
          border: 1px solid #307b37;
          color: #307b37;
          font-size: 0.14rem;
          cursor: pointer;
        }
        .btnsprice:hover {
          background: #307b37;
          color: #ffffff;
        }
      }
    }
  }
}
.shopll::after {
  content: "";
  width: 8.6rem;
}
</style>