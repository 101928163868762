import request from '../utils/request.js'

//获取合约列表
export function list(data) {
	return request({
		url: `/contract/list`,
		method: 'get',
		params:data
	})
}
//添加合约
export function add(data) {
	return request({
		url: `/contract/add`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//修改合约
export function edit(data) {
	return request({
		url: `/contract/update`,
		method: 'put',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//查询未被购买或未被租赁
export function statelist(data) {
	return request({
		url: `/contract/serverOrDevice?`,
		method: 'get',
		params:data
	})
}
//查询用户下已购买的服务器列表
export function purchased(data) {
	return request({
		url: `/contract/buy`,
		method: 'get',
		params:data
	})
}
//编辑合约下删除设备
export function editDelete(data) {
	return request({
		url: `/contract/delete/device`,
		method: 'DELETE',
		params:data
	})
}
//新增套餐
export function mealRecordaddOredit(data) {
	return request({
		url: `/mealRecord/addOredit`,
		method: 'POST',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//查询套餐列表
export function mealRecordlist(data) {
	return request({
		url: `/mealRecord/list`,
		method: 'get',
		params:data
	})
}
//删除套餐
export function mealRecordDelete(id) {
	return request({
		url: `/mealRecord/del/${id}`,
		method: 'DELETE',
	})
}
//购买套餐
export function userMealbuy(data) {
	return request({
		url: `/userMeal/buy`,
		method: 'POST',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//查询购买套餐
export function userMeallist(data) {
	return request({
		url: `/userMeal/list`,
		method: 'get',
		params:data
	})
}
//开启套餐
export function mediagetOpenMeal(data) {
	return request({
		url: `/media/getOpenMeal`,
		method: 'get',
		params:data
	})
}